/* import __COLOCATED_TEMPLATE__ from './conversation-rating.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

const SIX_HOURS = 6 * 3600;

export default class extends Component {
  @service permissionsService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  @tracked canvas = this.args.conversationRating[this.args.conversationRatingCanvasField];

  @tracked selected = this.hasCanvas ? 'custom' : 'csat';

  get hasCanvas() {
    return Boolean(this.args.conversationRating[this.args.conversationRatingCanvasField]);
  }

  get isCustomAppSelected() {
    return this.selected === 'custom';
  }

  get shouldStoreForUserOrLead() {
    return this.args.conversationRatingCanvasField === 'canvasForUsers'
      ? 'canvasCreationOptionsForUsers'
      : 'canvasCreationOptionsForLeads';
  }

  get blockUpdateAfterEnabled() {
    let fieldName = this.args.blockUpdateAfterField;
    return this.args.conversationRating.get(fieldName) !== null;
  }

  get blockRatingAfterEnabled() {
    let fieldName = this.args.blockRatingAfterField;
    return this.args.conversationRating.get(fieldName) !== null;
  }

  get blockUpdateAfterIsInvalid() {
    let fieldName = this.args.blockUpdateAfterField;
    let blockUpdateAfter = this.args.conversationRating.get(fieldName);
    return blockUpdateAfter < 0 || blockUpdateAfter > 96 * 3600;
  }

  get blockRatingAfterIsInvalid() {
    let fieldName = this.args.blockRatingAfterField;
    let blockRatingAfter = this.args.conversationRating.get(fieldName);
    return blockRatingAfter < 1 || blockRatingAfter > 96 * 3600;
  }

  get allowSaving() {
    return (
      this.args.conversationRating.hasDirtyAttributes &&
      this.toggleConversationRatingField.isIdle &&
      !this.blockUpdateAfterIsInvalid &&
      !this.blockRatingAfterIsInvalid
    );
  }

  workflowsBannerContent = this.intl
    .t(
      'operator.task-bots.users.when-a-conversation-is-closed.ask-for-conversation-rating.workflows-discoverability-info-banner',
    )
    .split('%');

  workflowsContentWrapper(text, order) {
    return text[order];
  }

  @action
  toggleBlockUpdateAfterField(fieldName) {
    if (this.args.conversationRating.get(fieldName) === null) {
      this.args.conversationRating.set(fieldName, SIX_HOURS);
    } else {
      this.args.conversationRating.set(fieldName, null);
    }
  }

  @action
  toggleBlockRatingAfterField(fieldName) {
    if (this.args.conversationRating.get(fieldName) === null) {
      this.args.conversationRating.set(fieldName, SIX_HOURS);
    } else {
      this.args.conversationRating.set(fieldName, null);
    }
  }

  @action
  setBlockUpdateAfter(event) {
    let fieldName = this.args.blockUpdateAfterField;
    this.args.conversationRating.set(fieldName, event.target.value * 3600);
  }

  @action
  setBlockRatingAfter(event) {
    let fieldName = this.args.blockRatingAfterField;
    this.args.conversationRating.set(fieldName, event.target.value * 3600);
  }

  @task({ drop: true })
  *toggleConversationRatingField(fieldName) {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    try {
      this.args.conversationRating.toggleProperty(fieldName);
      yield this.args.conversationRating.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.task-bots.notifications.settings-updated'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('operator.task-bots.notifications.settings-update-failed'),
      });
    }
  }

  @task({ drop: true })
  *insertMessengerApp(canvasJSON, messengerApp, cardCreationParams) {
    try {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'add_operator_closer_bot',
        object: messengerApp.id,
        context: 'conversation_ratings',
      });

      this.args.conversationRating[this.args.conversationRatingCanvasField] = canvasJSON;
      this.args.conversationRating[this.shouldStoreForUserOrLead] =
        cardCreationParams.canvas_creation_options;
      yield this.args.conversationRating.save();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('operator.task-bots.notifications.settings-update-failed'),
      });
    }
  }

  @task({ drop: true })
  *removeMessengerApp() {
    try {
      this.args.conversationRating[this.args.conversationRatingCanvasField] = null;
      yield this.args.conversationRating.save();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('operator.task-bots.notifications.settings-update-failed'),
      });
    }
  }
}
