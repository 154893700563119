/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Footer extends Component {
  @service intercomEventService;

  get ruleset() {
    return this.args.ruleset;
  }

  get showAutomaticallyDisabled() {
    return !this.tour.clientData.showAutomatically;
  }

  get tour() {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  @action
  setShowAutomatically(showAutomaticallyEnabled) {
    if (!this.tour.clientData) {
      this.tour.clientData = {};
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: showAutomaticallyEnabled
        ? 'turn_showing_automatically_on'
        : 'turn_showing_automatically_off',
      ruleset_id: this.ruleset.id,
    });
    this.tour.clientData.showAutomatically = showAutomaticallyEnabled;
  }
}
