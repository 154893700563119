/* import __COLOCATED_TEMPLATE__ from './tour-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { computed } from '@ember/object';
import { statColors } from 'embercom/models/data/stats-system/stats-constants';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  tagName: '',
  intercomEventService: service(),

  colors: statColors,
  tourObjectType: objectTypes.tour,

  completionCount: computed(
    'rulesetLinks.firstObject.object.stats.completion',
    'rulesetLinks.firstObject.object.steps.length',
    function () {
      let tour = this.ruleset.get('rulesetLinks.firstObject.object');
      if (tour.showStat('completion')) {
        return tour.get('stats.completion');
      } else {
        return NaN;
      }
    },
  ),

  actions: {
    trackViewTour(rulesetId) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'tour',
        context: 'from_index_page',
        ruleset_id: rulesetId,
      });
    },
  },
});
