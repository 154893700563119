/* import __COLOCATED_TEMPLATE__ from './embedded-styles.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import * as IntercomEmbed from '@intercom/intercom-embed';
import AppColor from 'embercom/lib/color';

export default Component.extend({
  appColor: notImplemented(),
  tagName: 'style',
  stylesheetBody: computed('appColor', function () {
    if (IntercomEmbed && IntercomEmbed.getStylesheet) {
      return IntercomEmbed.getStylesheet()
        .replace(/-intercom-app-color/g, this.appColor)
        .replace(/#intercom_composer/g, '.intercom_composer');
    } else {
      return '';
    }
  }),
  applicationColors: computed('appColor', function () {
    return new AppColor().generate_message_box_colors(this.appColor);
  }),
});
