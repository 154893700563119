/* import __COLOCATED_TEMPLATE__ from './content-anchor-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { cleanUrl } from '@intercom/embercom-prosemirror-composer/lib/helpers/clean-url';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

import AnchorEditor from '@intercom/embercom-prosemirror-composer/components/tools/anchor-editor';
import get from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';

type InsertableContent = {
  trigger_url: string;
  title: string;
  state: 'live' | 'paused' | 'draft';
  type: string;
  description?: string;
};

const ATTRIBUTE_INSERTER_CLASS = '.embercom-prosemirror-template-inserter';

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export default class ContentAnchorEditor extends AnchorEditor {
  @service declare appService: any;
  @service intercomEventService: any;
  @service declare intl: IntlService;

  @tracked urlValue;
  @tracked textValue = '';
  @tracked insertableContent: Array<InsertableContent> = [];
  @tracked isOpen = false;

  @tracked anchorPlaceholderElement: HTMLElement | null = null;

  constructor(owner: any, args: any) {
    super(owner, args);

    this.loadContent();
    this.urlValue = this.href ?? '';
  }

  focusIfEmptyModifier = modifier((element: HTMLInputElement) => {
    this.focusIfEmpty(element);
  });

  documentClickListener = this.onDocumentClick.bind(this);

  @action didOpen() {
    this.isOpen = true;
    this.textValue = '';
    this.urlValue = this.href ?? '';

    if (this.args.composer.anchorInsertionMarker) {
      this.anchorPlaceholderElement = document.querySelector(
        `#${this.args.composer.anchorInsertionMarker}`,
      );

      this.args.composer.config.analytics?.trackAnalyticsEvent('clicked', {
        context: 'content_anchor_editor',
      });
    }

    document.addEventListener('mousedown', this.documentClickListener);
  }

  get useTeammateUrls() {
    return this.args.options.useTeammateUrls;
  }

  get typesToIconsMap() {
    return {
      'Internal article': 'locked',
      Article: 'article',
      Tour: 'product-tours',
      Survey: 'survey-outline',
      News: 'news-filled',
      Checklist: 'checklist',
    };
  }

  @action didClose() {
    this.isOpen = false;
    this.urlValue = '';
    this.anchorPlaceholderElement = null;
    document.removeEventListener('mousedown', this.documentClickListener);
  }

  @action onInsert(
    attributeTemplate: string,
    attributeTemplateStartPosition?: number,
    attributeTemplateEndPosition?: number,
  ): void {
    super.onInsert(attributeTemplate, attributeTemplateStartPosition, attributeTemplateEndPosition);
    this.urlValue = this.href;
    this.update();
  }

  onDocumentClick() {
    // Don't close the ContentAnchorEditor if the detected click was for the template attribute popover.
    if (document.querySelector(ATTRIBUTE_INSERTER_CLASS)) {
      return;
    }

    if (this.args.composer.anchorInsertionMarker) {
      this.close();
    } else if (this.isOpen) {
      if (!this.href && !this.intercomContentLink) {
        this.remove();
      } else {
        this.close();
      }
    }
  }

  addLink(marker: string) {
    if (this.textValue && this.urlValue) {
      this.args.composer.commands.insertAtMarker(marker, [
        {
          type: 'paragraph',
          text: `<a href="${cleanUrl(this.urlValue)}">${this.textValue}</a>`,
        },
      ]);
    } else {
      this.args.composer.commands.insertAtMarker(marker, [
        {
          type: 'paragraph',
          text: ``,
        },
      ]);
    }
  }

  async loadContent() {
    let content: Array<InsertableContent> = [];

    if (this.useTeammateUrls) {
      content = await get({
        url: '/ember/content_service/contents/teammate_url_insertable_content',
        data: {
          app_id: this.appService.app.id,
          entity_types: this.args.options.entityTypes,
        },
      });
    } else {
      content = await get({
        url: '/ember/content_service/contents/insertable_content',
        data: {
          app_id: this.appService.app.id,
          multi_help_center_aware: true,
        },
      });
    }

    this.args.composer.config.analytics?.trackAnalyticsEvent('loaded_content', {
      context: 'content_anchor_editor',
      num_smart_links: content.length,
    });

    content.forEach((c) => {
      if (!c.title) {
        c.title = this.intl.t('tools.content-anchor-editor.untitled');
      }
    });

    this.insertableContent = content;
  }

  get filteredContent() {
    let liveContent = this.insertableContent.filter((content) => content.state === 'live');

    if (this.urlValue && !this.intercomContentLink) {
      return liveContent.filter((content) =>
        content.title.toLowerCase().includes(this.urlValue.toLowerCase()),
      );
    } else {
      return liveContent;
    }
  }

  get intercomContentLink(): InsertableContent | undefined {
    if (
      this.urlValue?.startsWith('intercom://') ||
      this.insertableContent.map((c) => c.trigger_url).includes(this.urlValue)
    ) {
      return this.insertableContent.find((content) => content.trigger_url === this.urlValue);
    } else {
      return undefined;
    }
  }

  @action removeContentLink() {
    this.args.composer.config.analytics?.trackAnalyticsEvent('removed', {
      context: 'content_anchor_editor',
      is_smart_link: Boolean(this.intercomContentLink),
    });
    this.update('');
  }

  @action close() {
    if (this.args.composer.anchorInsertionMarker) {
      this.addLink(this.args.composer.anchorInsertionMarker);
      this.args.composer.anchorInsertionMarker = undefined;
    } else {
      this.update();

      if (!this.inputIsBlank) {
        this._moveSelectionToEndOfAnchor();
      }
    }

    next(this, () => {
      this.args.composer.commands.focus();
    });
  }

  @action
  update(manualHref: string | undefined = undefined): void {
    this.urlValue = manualHref ?? cleanUrl(this.hrefInput.value);

    this.args.composer.config.analytics?.trackAnalyticsEvent('inserted', {
      context: 'content_anchor_editor',
      is_smart_link: Boolean(this.intercomContentLink),
      smart_link_type: this.intercomContentLink?.type,
      smart_link_url: this.intercomContentLink?.trigger_url,
    });

    if (!this.anchorMark) {
      return;
    }

    let href = manualHref ?? cleanUrl(this.hrefInput.value);
    let newMark = this.anchorMarkType.create({ href });
    let transaction = this.args.composer.state.prosemirrorState.tr.addMark(
      this.anchorPosition.from,
      this.anchorPosition.to,
      newMark,
    );

    if (transaction) {
      this.args.composer.commands.dispatch(transaction);
    }
  }

  willDestroy() {
    super.willDestroy();
    document.removeEventListener('mousedown', this.documentClickListener);
  }
}
