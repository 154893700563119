/* import __COLOCATED_TEMPLATE__ from './reporting-stacked-bar-datum.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import {
  dateRangeBucketFormatter,
  isBucketGreaterThanDay,
} from 'embercom/lib/inbox/date-range-bucket-formatter';

const DATE_PATTERN = 'MMM D, ddd';

export default Component.extend({
  intl: service(),
  attributeBindings: ['data-test-reporting-stacked-bar-tooltip'],
  'data-test-reporting-stacked-bar-tooltip': true,
  formattedCurrentDate: computed('data.date', function () {
    return moment(this.data.date).format(DATE_PATTERN);
  }),
  formattedCurrentRangeDate: computed(
    'intl.locale',
    'data.{aggregationInterval,date,rangeEnd,rangeStart,timezone}',
    function () {
      return dateRangeBucketFormatter(
        this.intl,
        this.get('data.timezone'),
        this.data.date,
        this.get('data.aggregationInterval'),
        this.get('data.rangeStart'),
        this.get('data.rangeEnd'),
      );
    },
  ),
  isBucketGreaterThanDay: computed('data.aggregationInterval', function () {
    return isBucketGreaterThanDay(this.get('data.aggregationInterval'));
  }),
});
