/* import __COLOCATED_TEMPLATE__ from './anchor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
// @ts-ignore
import { AnchorIconTypes, AnchorTypes } from '@intercom/intersection-shared-models/models/tooltip';
import { type Tooltip } from '../editor';

type Args = {
  tooltip: Tooltip;
};

export default class Card extends Component<Args> {
  get isElementAnchor() {
    return this.args.tooltip.anchorType === AnchorTypes.useElement;
  }

  get isAnchorIconTypeIcon() {
    return this.args.tooltip.anchorIconType === AnchorIconTypes.icon;
  }

  get isIconAnchor() {
    return this.args.tooltip.anchorType === AnchorTypes.icon;
  }

  get isHotspotAnchor() {
    return this.args.tooltip.anchorType === AnchorTypes.hotspot;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Card::Anchor': typeof Card;
    'tooltip-group/card/anchor': typeof Card;
  }
}
