/* import __COLOCATED_TEMPLATE__ from './delivery.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { type Tooltip } from '../editor';

type Args = {
  tooltip: Tooltip;
};

export default class Delivery extends Component<Args> {
  get tooltipHasAnyUrlPredicates() {
    return this.args.tooltip.urlPredicateGroup.predicates.length;
  }

  get icon() {
    return this.tooltipHasAnyUrlPredicates ? 'link' : 'globe';
  }

  get content() {
    if (this.tooltipHasAnyUrlPredicates) {
      return this.args.tooltip.urlPredicatePreview;
    }

    return 'Sitewide';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Card::Delivery': typeof Delivery;
    'tooltip-group/card/delivery': typeof Delivery;
  }
}
