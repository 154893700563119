/* import __COLOCATED_TEMPLATE__ from './branching-qualification-question-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';

const DEFAULT_QUESTION = "What term best describes who you are or what you're looking for?";

export default Component.extend({
  actions: {
    finishedEditingBranchingQuestion() {
      if (isEmpty(this.customQuestion)) {
        this.set('customQuestion', null);
      }
      this.set('isEditingBranchingQuestion', false);
    },
    startedEditingBranchingQuestion() {
      if (this.customQuestion) {
        return;
      }
      this.set('customQuestion', DEFAULT_QUESTION);
    },
  },
});
