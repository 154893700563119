/* import __COLOCATED_TEMPLATE__ from './element-selection.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { type Tooltip } from '../editor';

type Args = {
  tooltip: Tooltip;
};

export default class ElementSelection extends Component<Args> {
  get icon() {
    return this.args.tooltip.textSelector ? 'recipient-data' : 'code';
  }

  get elementSelectionContent() {
    return this.args.tooltip.textSelector
      ? this.args.tooltip.textSelector
      : this.args.tooltip.cssSelector;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Card::ElementSelection': typeof ElementSelection;
    'tooltip-group/card/element-selection': typeof ElementSelection;
  }
}
