/* import __COLOCATED_TEMPLATE__ from './hotspot-anchor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { type Tooltip } from '../../editor';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

type Args = {
  tooltip: Tooltip;
};

export default class HotspotAnchor extends Component<Args> {
  get hotspotCSS() {
    return sanitizeHtml(
      `content: ""; position: absolute; left: 0px; top: 0px; height: 16px; width: 16px; border-radius: 50%; background: ${this.args.tooltip.anchorColor} ; display: block; animation: ic-hotspot-pulse-animation 2s ease 0s infinite;`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Card::Anchors::HotspotAnchor': typeof HotspotAnchor;
    'tooltip-group/card/anchors/hotspot-anchor': typeof HotspotAnchor;
  }
}
