/* import __COLOCATED_TEMPLATE__ from './team-membership-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, notEmpty, or, filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  isLoading: false,
  hasTeams: notEmpty('assignableTeamsAdminIsAMemberOf'),
  isNotEmpty: or('hasTeams', 'isLoading'),
  assignableTeamsAdminIsAMemberOf: filter('app.assignableTeams', function (team) {
    return team.get('member_ids').includes(parseInt(this.get('admin.id'), 10));
  }),
});
