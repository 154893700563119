/* import __COLOCATED_TEMPLATE__ from './filter-links.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  filters: computed(
    'allRulesets.length',
    'draftRulesets.length',
    'publishedRulesets.length',
    function () {
      return [
        { key: 'all', label: 'All', count: this.allRulesets.length },
        { key: 'published', label: 'Live', count: this.publishedRulesets.length },
        { key: 'draft', label: 'Draft', count: this.draftRulesets.length },
      ];
    },
  ),
  currentState: computed('state', function () {
    return this.state || 'all';
  }),
  actions: {
    selectFilter(filterKey) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'filter_toggled',
        object: 'tours',
        filter_value: filterKey,
      });
    },
  },
});
