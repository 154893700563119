/* import __COLOCATED_TEMPLATE__ from './update-tour-build-url.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { IP_ADDRESS_REGEX } from 'embercom/components/tours/pre-tour-setup';
import isValidUrl from 'embercom/lib/url-validator';

export default class UpdateTourBuildURL extends Component {
  @tracked updatedUrl = this.args.currentUrl;

  get hasValidUrl() {
    return isValidUrl(this.updatedUrl) || IP_ADDRESS_REGEX.test(this.updatedUrl);
  }

  get hasInvalidUrl() {
    return !this.hasValidUrl;
  }

  @action
  closeWindow() {
    window.close();
  }

  @action
  openTourBuilderOnEnter(event) {
    if (event.keyCode === 13 && this.hasValidUrl) {
      this.saveAndOpenTourBuilder();
    }
  }

  @action
  saveAndOpenTourBuilder() {
    window.opener.postMessage({ updatedURLForTour: this.updatedUrl }, '*');
  }
}
