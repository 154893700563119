/* import __COLOCATED_TEMPLATE__ from './tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { type Tooltip } from './editor';
import type OutboundContent from 'embercom/models/customization-options/outbound-content';
interface Args {
  tooltip: Tooltip;
  isEditing: boolean;
  customizationOptions: OutboundContent;
}

export default class TooltipComponent extends Component<Args> {
  get placedAt() {
    return this.args.tooltip.cssSelector || this.args.tooltip.textSelector;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Tooltip': typeof TooltipComponent;
    'tooltip-group/tooltip': typeof TooltipComponent;
  }
}
