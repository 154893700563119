/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { type Tooltip } from '../editor';

type Args = {
  tooltip: Tooltip;
  customizationOptions: { backgroundColor: string; fontColor: string };
};

export default class Content extends Component<Args> {
  get backgroundColor() {
    return sanitizeHtml(`background-color: ${this.args.customizationOptions.backgroundColor};`);
  }

  get backgroundAndFontColor() {
    return sanitizeHtml(
      `background-color: ${this.args.customizationOptions.backgroundColor}; color: ${this.args.customizationOptions.fontColor};`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Card::Content': typeof Content;
    'tooltip-group/card/content': typeof Content;
  }
}
