/* import __COLOCATED_TEMPLATE__ from './attribute-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class AttributeSelector extends Component {
  @service attributeService;
  @service intercomEventService;
  @tracked attributeList = [];

  get selectItems() {
    return this.attributeService.botAutoMessageCollectableAttributeGroupList.map(
      (attributeGroup) => ({
        heading: attributeGroup.heading,
        attributes: attributeGroup.attributes.filter(
          (attribute) =>
            !this.args.attributes.includes(attribute.identifier) ||
            attribute.identifier === this.args.selectedAttribute,
        ),
      }),
    );
  }

  @action
  selectAttribute(value) {
    this.args.onAttributeItemSelection(this.args.parentAttributeIndex, value.identifier);
  }

  @action
  onAttributeCreated() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'attribute_created',
      object: 'upfront-collection-users',
      place: 'task_bot_settings',
    });
  }
}
