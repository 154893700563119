/* import __COLOCATED_TEMPLATE__ from './tag-filter.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

export default class TagFilter extends Component {
  @service appService;
  @service store;
  @service intl;
  @service intercomEventService;

  @tracked selectedValues = [];
  @tracked name = '';
  isOpen = this.args.initiallyOpened || false;

  constructor() {
    super(...arguments);
    this.name = this.intl.t('common.tag-filter.label');
  }

  filterResetable = modifier((_, [v]) => {
    let newValues = v || [];

    this.filterByTags(newValues.slice());
  });

  get app() {
    return this.appService.app;
  }

  get details() {
    return this.intl.t('common.tag-filter.details', {
      tag: this.sampleSelectedTagName,
      count: (this.selectedValues || this.args.selectedTagValues || []).length,
      additional: (this.selectedValues || this.args.selectedTagValues || []).length - 1,
    });
  }

  get hasAvailableTags() {
    return this.args.availableTags.length > 0;
  }

  get hasNoAvailableTags() {
    return !this.hasAvailableTags;
  }

  get sampleSelectedTagName() {
    let selected = this.selectedValues || this.args.selectedTagValues;
    if (isPresent(selected)) {
      return this.store.peekRecord('tag', selected[0]).name;
    }
  }

  get tags() {
    if (this.hasNoAvailableTags) {
      return [{ ...this.args.emptyTagsEntry, isDisabled: true }];
    }
    return this._getSortedTags();
  }

  @action filterByTags(selectedValues) {
    this.selectedValues = selectedValues;
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'tag_filter',
    });
  }

  @action onClose() {
    this.isOpen = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'applied_tags',
      object: 'tag_filter',
      count: this.selectedValues.length,
      tags: this.selectedValues.map((tagId) => this.store.peekRecord('tag', tagId).name),
    });
    this.args.filterContent('selectedTagValues', this.selectedValues);
  }

  _getSortedTags() {
    let tagObjects = this.args.availableTags.map((tag) => ({
      text: tag.name,
      value: tag.id,
      isSelected: this.args.selectedTagValues
        ? this.args.selectedTagValues.includes(tag.id)
        : false,
    }));
    return tagObjects.sortBy('isSelected').reverse();
  }
}
