/* import __COLOCATED_TEMPLATE__ from './view-or-edit-insertion-url.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph', 'heading'];
  allowedInline = [];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: false,
  };

  constructor({ placeholder, resolver }) {
    super();

    this.placeholder = placeholder;
    this.templating = { picker: 'common/attribute-picker', resolver };
  }
}
export default class ViewOrEditInsertionURL extends Component {
  blocksDocument;
  composerConfig;

  @service attributeService;
  @service appService;
  @service intl;

  constructor() {
    super(...arguments);
    this.blocksDocument = new BlocksDocument(this.editableBlocks);

    this.composerConfig = new ComposerConfig({
      placeholder: this.intl.t('outbound.product-tours.enter-a-url'),
      resolver: this.resolver,
    });
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.tourUrlComposerAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
    });
  }

  get app() {
    return this.appService?.app;
  }

  get previewBlocks() {
    return [
      {
        type: 'paragraph',
        // render-blocks hates plain ampersands
        text: (this.args.tour.copyableUrl ?? '').replace('&', '&amp;'),
      },
    ];
  }

  get editableBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.args.tour?.insertableUrl ?? '',
      },
    ];
  }

  @action
  updateUrl(blocksDoc) {
    if (this.isDestroying || this.args.tour?.isDestroying) {
      return;
    }
    let blocks = blocksDoc.blocks;
    let blockText = blocks.firstObject?.text ?? '';
    let textContent = htmlToTextContent(blockText);
    blockText = textContent.trim();
    this.args.tour.set('insertableUrl', blockText);
  }
}
