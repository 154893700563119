/* import __COLOCATED_TEMPLATE__ from './qualify-leads.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

export default class QualifyLeadsComponent extends Component {
  @service intercomEventService;
  @service intercomConfirmService;
  @service appService;
  @service intl;

  @tracked displayValidationErrors = false;

  get app() {
    return this.appService.app;
  }

  get workflowsBannerContent() {
    return this.intl
      .t('operator.task-bots.leads.when-leads-start-a-conversation.qualify-leads.banner-text')
      .split('%');
  }

  workflowsContentWrapper(text, order) {
    return text[order];
  }

  updateQualificationType(type) {
    this.recordQualificationTypeChange(type);
    this.args.qualifyLeads.qualificationType = type;
  }

  recordQualificationTypeChange(type) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: type,
      place: 'task_bots',
    });
  }

  @task({ drop: true })
  *changeQualificationType(type) {
    let currentQualificationType = this.args.qualifyLeads.currentQualificationType;
    if (currentQualificationType.hasDirtyAttributes) {
      let confirmed = yield this.unsavedChangesWarning();
      if (confirmed) {
        currentQualificationType.rollbackAttributes();
        this.updateQualificationType(type);
      }
    } else {
      this.updateQualificationType(type);
    }
  }

  unsavedChangesWarning() {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('operator.task-bots.leads.confirm-not-saved.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('operator.task-bots.leads.confirm-not-saved.confirm-button'),
      cancelButtonText: this.intl.t('operator.task-bots.leads.confirm-not-saved.cancel-button'),
      body: this.intl.t('operator.task-bots.leads.confirm-not-saved.body'),
    });
  }

  @action
  beforeSave() {
    this.displayValidationErrors = false;
    let currentQualificationType = this.args.qualifyLeads.currentQualificationType;
    currentQualificationType.sanitizeAttributes();
  }

  @action
  afterSave() {
    this.displayValidationErrors = true;
    // this is necessary due to Ember weirdness which wasn't marking the
    // model fragments (qualification attributes and rules) as no longer dirty
    this.args.qualifyLeads.currentQualificationType.rollbackAttributes();
  }
}
