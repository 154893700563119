/* import __COLOCATED_TEMPLATE__ from './pre-tour-setup.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import isValidUrl from 'embercom/lib/url-validator';
import { computed } from '@ember/object';
import { not, or, readOnly } from '@ember/object/computed';
import storage from 'embercom/vendor/intercom/storage';
import ENV from 'embercom/config/environment';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';

const ENTER = 13;
const STORAGE_KEY = 'tours-teammate-has-seen-pre-tour-instructions';

const RECENT_TOOLTIPS_URLS_KEY = 'ic-recent-tooltips-urls';
const RECENT_TOURS_URLS_KEY = 'ic-recent-tours-urls';

export const IP_ADDRESS_REGEX = /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/;

export default Component.extend({
  classNames: ['flex-auto', 'flex', 'flex-col', 'tours__pre-tour__container'],
  attributeBindings: ['data-test-pre-tour-setup'],
  'data-test-pre-tour-setup': true,

  appService: service(),
  app: readOnly('appService.app'),

  isMessengerInstalled: or(
    'app.hasAnyInstalledAtDate',
    'app.first_identified_request_at',
    'app.verifiedLoggedInInstallAt',
    'app.first_anonymous_request_at',
    'app.verifiedLoggedOutInstallAt',
  ),

  mode: 'url',
  url: null,
  isTooltipsMode: false,

  init() {
    this._super(...arguments);
    if (!this.isMessengerInstalled) {
      // We may have just come from the on-boarding guide after having installed the Messenger
      // in which case the app model won't have been reloaded since.
      this.app.reload();
    }
    if (this.isTooltipsMode) {
      document.title = 'Design Your Tooltips - Intercom';
    } else {
      document.title = 'Design Your Product Tour - Intercom';
    }

    this.setRecentURLs();
  },

  setRecentURLs() {
    let storageKey = this.isTooltipsMode ? RECENT_TOOLTIPS_URLS_KEY : RECENT_TOURS_URLS_KEY;

    let recentURLs = storage.get(storageKey) ?? [];

    this.set('recentURLs', recentURLs);
  },

  hasValidUrl: computed('url', function () {
    return isValidUrl(this.url) || IP_ADDRESS_REGEX.test(this.url);
  }),

  hasInvalidUrl: not('hasValidUrl'),

  openTourBuilderAfterTimeout: task(function* () {
    yield timeout(ENV.APP._5000MS);
    this.send('postUrl');
  }),

  actions: {
    installMessenger() {
      window.opener.postMessage({ installMessenger: true }, '*');
      window.close();
    },
    progress() {
      let hasSeenInstructions = !!storage.get(STORAGE_KEY);
      if (hasSeenInstructions) {
        this.send('postUrl');
      } else {
        storage.set(STORAGE_KEY, true);
        this.set('mode', 'instructions');
        this.openTourBuilderAfterTimeout.perform();
      }
    },
    tryProgress(event) {
      if (event.keyCode === ENTER && this.hasValidUrl) {
        this.send('progress');
      }
    },
    updateUrl(url) {
      this.set('url', url.trim());
    },
    postUrl(url) {
      url = url || this.url;
      let recentURLs = [url, ...this.recentURLs].uniq().slice(0, 5);

      if (this.isTooltipsMode) {
        storage.set(RECENT_TOOLTIPS_URLS_KEY, recentURLs);
        window.opener.postMessage({ urlForTooltipsBuilder: url }, '*');
      } else {
        storage.set(RECENT_TOURS_URLS_KEY, recentURLs);
        window.opener.postMessage({ urlForTourBuilder: url }, '*');
      }
    },
  },
});
