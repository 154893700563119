/* import __COLOCATED_TEMPLATE__ from './time-from-now-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import DateFormatter from 'embercom/vendor/intercom/date-formatter';
import moment from 'moment-timezone';

export default Component.extend({
  isJustNow: equal('time', 'Just now'),
  time: computed('timestamp', function () {
    let previousLocale = moment.locale();
    moment.locale('en-time-from-now', {
      relativeTime: {
        future: 'Just now',
        past: '%s',
        s: 'Just now',
        m: '1m ago',
        mm: '%dm ago',
        h: '1h ago',
        hh: '%dh ago',
        d: '1d ago',
        dd: '%dd ago',
        M: '1mth ago',
        MM: '%dmth ago',
        y: '1y ago',
        yy: '%dy ago',
      },
    });
    let time = DateFormatter.forcedPastFromNowRealTime(this.timestamp);
    moment.locale(previousLocale);
    return time;
  }),
});
