/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const MODAL_STATES = {
  closed: 0,
  tagging: 1,
  untagging: 2,
};

export default class TaggerComponent extends Component {
  @service store;
  @service appService;
  @tracked modalState = MODAL_STATES.closed;

  get showTagModal() {
    return this.modalState === MODAL_STATES.tagging || this.modalState === MODAL_STATES.untagging;
  }

  get groupList() {
    return [
      {
        isFilterable: true,
        items: this.items,
      },
    ];
  }

  get items() {
    return this.appService.app.tags.map((tag) => {
      return {
        text: tag.name,
        value: tag.id,
        icon: 'tag',
      };
    });
  }

  get tags() {
    return this.args.tagger.tagIds.map((tagId) => {
      return this.store.peekRecord('tag', tagId);
    });
  }

  get untags() {
    return this.args.tagger.untagIds.map((tagId) => {
      return this.store.peekRecord('tag', tagId);
    });
  }

  addTag(item) {
    this.args.tagger.addTag(item);
  }

  addUntag(item) {
    this.args.tagger.addUntag(item);
  }

  @action
  removeTag(tagId) {
    this.args.tagger.removeTag(tagId);
  }

  @action
  removeUntag(tagId) {
    this.args.tagger.removeUntag(tagId);
  }

  @action
  openTagModal(mode) {
    this.modalState = MODAL_STATES[mode];
  }

  get taggable() {
    return {
      self: this,
      tags: [],
      taggings: [],
      type: 'content',
      updateTaggings(admin, addedTags, removedTags) {
        if (this.self.modalState === MODAL_STATES.tagging) {
          addedTags.forEach((tag) => {
            this.self.addTag(tag.get('id'));
          });
        } else if (this.self.modalState === MODAL_STATES.untagging) {
          addedTags.forEach((tag) => {
            this.self.addUntag(tag.get('id'));
          });
        }
      },
    };
  }
}
