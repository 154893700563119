/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class TaggerSummary extends Component {
  @service store;
  @service appService;

  get tags() {
    return this.args.tagger.tagIds.map((tagId) => {
      return this.store.peekRecord('tag', tagId);
    });
  }

  get untags() {
    return this.args.tagger.untagIds.map((tagId) => {
      return this.store.peekRecord('tag', tagId);
    });
  }
}
