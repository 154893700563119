/* import __COLOCATED_TEMPLATE__ from './article-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import type ArticleSuggestion from 'embercom/models/operator-settings/article-suggestions';

interface Signature {
  Args: {
    articleSuggestions: ArticleSuggestion;
    body: $TSFixMe;
    enabled: boolean;
  };
}

const ArticleSuggestions = templateOnlyComponent<Signature>();
export default ArticleSuggestions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TaskBots::ArticleSuggestions': typeof ArticleSuggestions;
    'task-bots/articles-suggestions': typeof ArticleSuggestions;
  }
}
