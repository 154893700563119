/* import __COLOCATED_TEMPLATE__ from './messenger-preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, and } from '@ember/object/computed';
import { includes } from '@intercom/pulse/lib/computed-properties';
import $ from 'jquery';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['flex-none', 'flex', 'flex-col', 'messenger-settings__preview__sticky'],
  classNameBindings: [
    'canUseFinLaunch:messenger-preview-redesign__conversation:messenger-preview__conversation',
  ],
  scrollingContainerClass: computed('canUseFinLaunch', function () {
    return this.canUseFinLaunch
      ? 'messenger-preview-redesign__scrolling-area'
      : 'messenger-preview__home__scrolling-area';
  }),

  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  canUseFinLaunch: readOnly('appService.app.canUseFinLaunch'),

  viewingConversationRatingTask: and('openSectionName', 'openSectionIsConversationRating'),
  openSectionIsConversationRating: includes('openSectionName', 'conversation-rating'),

  didUpdateAttrs() {
    this._super(...arguments);
    let scrollingContainer = $(`.${this.scrollingContainerClass}`);
    if (this.openSectionName) {
      scrollingContainer.animate({ scrollTop: scrollingContainer[0].scrollHeight }, 1000);
    } else {
      scrollingContainer.animate({ scrollTop: 0 }, 1000);
    }
  },
});
