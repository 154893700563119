/* import __COLOCATED_TEMPLATE__ from './marketo-hovercard.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class TooltipsCrmMarketoHovercard extends Component {
  @service intercomEventService;

  @action
  trackViewedEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'marketo_lead',
      action: 'viewed',
    });
  }
}
