/* import __COLOCATED_TEMPLATE__ from './main-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly, filterBy, setDiff, union } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  intercomEventService: service(),
  classNames: ['flex-auto', 'flex', 'flex-col'],

  draftRulesets: setDiff('rulesets', 'publishedRulesets'),

  _liveRulesets: filterBy('rulesets', 'isLive', true),
  _insertableTourRulesets: filterBy('rulesets', 'rulesetLinks.firstObject.object.insertable', true),
  publishedRulesets: union('_liveRulesets', '_insertableTourRulesets'),

  rulesetsToList: computed('rulesets', 'state', function () {
    if (this.state === 'draft') {
      return this.draftRulesets;
    } else if (this.state === 'published') {
      return this.publishedRulesets;
    } else {
      return this.rulesets;
    }
  }),

  isMessengerInstalled: readOnly('app.hasAnyInstalledAtDate'),
});
