/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  classNames: ['flex-none', 'u__pad__60'],

  errorsByPanel: computed(
    'ruleset.validations.messages.[]',
    'tour.validations.messages.[]',
    'validationPanelMap',
    'insertionVisited',
    function () {
      let validationPanelMap = this.validationPanelMap;
      let validationKeys = Object.keys(validationPanelMap);

      return validationKeys.reduce((res, validationKey) => {
        if (this.get(`${validationKey}.isValid`)) {
          if (validationKey !== 'tour.validations.attrs.insertableUrl' || this.insertionVisited) {
            return res;
          }
        }

        let errorMessages = this.get(`${validationKey}.messages`);
        let validation = validationPanelMap[validationKey];

        this.intercomEventService.trackAnalyticsEvent({
          action: 'validation_error',
          object: `tour_editor_${validation.sectionName}`,
          ruleset_id: this.ruleset.id,
        });

        return [
          ...res,
          {
            panel: validation.sectionName,
            message: validation.overrideMessage || errorMessages,
            index: validation.index,
          },
        ];
      }, []);
    },
  ),

  actions: {
    openSection(name) {
      this.openSection(name);
    },
  },
});
