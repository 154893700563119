/* import __COLOCATED_TEMPLATE__ from './upgrade-install-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { startTour } from 'embercom/lib/intercom-widget-helper';

const GETTING_STARTED_TOUR_ID = 2309;

export default class UpgradeInstallBanner extends Component {
  @service appService;
  @service customerService;
  @service onboardingHomeService;

  get app() {
    return this.appService.app;
  }

  get product() {
    return this.app.productToursProduct;
  }

  get messengerIsInstalled() {
    return (
      this.app.hasAnyInstalledAtDate ||
      this.app.first_identified_request_at ||
      this.app.verifiedLoggedInInstallAt ||
      this.app.first_anonymous_request_at ||
      this.app.verifiedLoggedOutInstallAt
    );
  }

  get showInstallBanner() {
    return (
      !this.messengerIsInstalled &&
      !this.args.onlyAllowLoggedInBanner &&
      this.onboardingHomeBannerNotShowingInstallMessenger
    );
  }

  get onboardingHomeBannerNotShowingInstallMessenger() {
    return (
      !this.onboardingHomeService.guide?.installMessengerStep?.available &&
      !this.onboardingHomeService.guide?.installLoggedInMessengerStep?.available
    );
  }

  get showLoggedInInstallBanner() {
    return (
      this.messengerIsInstalled &&
      !(this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt)
    );
  }

  get upgradeMessageKey() {
    return this.args.upgradeMessageKey || 'outbound.product-tours.paywall-banner.upgrade';
  }

  get installForUsersMessageKey() {
    return (
      this.args.installForUsersMessageKey ||
      'outbound.product-tours.install-banners.to-send-product-tours-to-users'
    );
  }

  get installMessageKey() {
    return (
      this.args.installMessageKey || 'outbound.product-tours.install-banners.to-use-product-tours'
    );
  }

  @action
  async startTour() {
    startTour(GETTING_STARTED_TOUR_ID);
  }
}
