/* import __COLOCATED_TEMPLATE__ from './contact-collection.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  intl: service(),
  router: service(),
  appService: service(),

  workflowsBannerContent: computed('intl.locale', function () {
    return this.intl
      .t(
        'operator.task-bots.leads.when-leads-start-a-conversation.ask-for-contact-details.workflows-discoverability-banner',
      )
      .split('%');
  }),

  workflowsContentWrapper(text, order) {
    return text[order];
  },

  actions: {
    toggleSmsCollection() {
      this.toggleProperty('contactCollection.collectSms');
    },
  },
});
