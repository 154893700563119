/* import __COLOCATED_TEMPLATE__ from './tag-or-segment-pill-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { alias, and, equal, or, reads, not } from '@ember/object/computed';
import Component from '@ember/component';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';

export default Component.extend({
  tagName: '',
  link: true,
  showRemove: true,
  tag: alias('tagOrSegment'),
  hasDelete: and('isTag', 'showRemove'),
  isCompany: equal('taggable.resourceName', 'companies'),
  isUser: equal('taggable.constructor.modelName', 'user'),
  isArticle: equal('taggable.constructor.modelName', 'article-multilingual'),
  isAnswer: equal('taggable.constructor.modelName', 'custom-answers/custom-answer'),
  isUserOrCompany: or('isUser', 'isCompany'),
  isSegment: reads('tagOrSegment.isSegment'),
  isTag: not('isSegment'),
  icon: ternary('isTag', 'tag', 'segment'),

  attributeIfUser: ternary('isTag', 'manual_tag_ids', 'tag_ids'),
  attributeIfCompany: computed('attributeIfUser', function () {
    return `company.${this.attributeIfUser}`;
  }),
  attribute: ternaryToProperty('isCompany', 'attributeIfCompany', 'attributeIfUser'),

  predicateBase: ternaryToProperty('isSegment', 'segmentPredicateBase', 'tagPredicateBase'),
  tagPredicateBase: { comparison: 'eq', type: 'manual_tag' },
  segmentPredicateBase: { comparison: 'in', type: 'tag' },

  removeTag() {
    if (this.onRemove) {
      this.onRemove(this.tag);
    }
    this.taggable.removeTagging(this.tag);
  },

  userOrCompanyListUrl: computed('tagOrSegment', function () {
    if (this.isUserOrCompany) {
      return B64FilterParamEncoder.filterUrlFromPredicates(this.taggable, this.asPredicates);
    }
  }),

  asPredicates: computed('tagOrSegment', function () {
    let predicate = this.predicateBase;
    predicate.attribute = this.attribute;
    predicate.value = this.get('tagOrSegment.id');
    return { predicates: [predicate] };
  }),

  actions: {
    removeTag() {
      this.set('isVisible', false);
      this.removeTag();
    },
  },
});
