/* import __COLOCATED_TEMPLATE__ from './teammate-role-data-questions.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  departmentsAndRoles,
  useCases,
  experiences,
  intentLevels,
  tools,
  newDepartments,
} from 'embercom/models/data/teammate-role-data';

export default class TeammateRoleDataQuestionsComponent extends Component {
  @service intl;
  @service appService;

  maxCharacters = 100;

  get app() {
    return this.appService.app;
  }

  get useCaseList() {
    return Object.entries(useCases).map(([value, translationKey]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${translationKey}`),
      value,
    }));
  }

  get departmentList() {
    return Object.entries(departmentsAndRoles).map(([key, value]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${value.name}`),
      value: key,
    }));
  }

  get roleList() {
    let rolesForDepartment = departmentsAndRoles[this.args.selectedDepartment]?.roles ?? [];
    return Object.entries(rolesForDepartment).map(([value, translationKey]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${translationKey}`),
      value,
    }));
  }

  get roleIsOther() {
    return this.args.selectedRole && this.args.selectedRole.endsWith('_other');
  }

  get experienceList() {
    return Object.entries(experiences).map(([value, translationKey]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${translationKey}`),
      value,
    }));
  }

  get intentLevelList() {
    return Object.entries(intentLevels).map(([value, translationKey]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${translationKey}`),
      value,
    }));
  }

  get toolList() {
    return Object.entries(tools).map(([value, translationKey]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${translationKey}`),
      value,
    }));
  }

  get newDepartmentsList() {
    return Object.entries(newDepartments).map(([value, translationKey]) => ({
      text: this.intl.t(`components.teammate-role-data.questions.${translationKey}`),
      value,
    }));
  }
}
