/* import __COLOCATED_TEMPLATE__ from './template-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

const SMART_LINKS_IDS = '971';
const ANIMATED_BEACON_ID = '972';
const TEXT_LABEL_ID = '973';
const CLASSIC_ID = '974';

type TemplateId = '971' | '972' | '973' | '974';

interface Args {
  templateId: TemplateId;
}

const VIDEOS: Record<TemplateId, string> = {
  [SMART_LINKS_IDS]: assetUrl('/assets/videos/tooltips/smart-links.mp4'),
  [ANIMATED_BEACON_ID]: assetUrl('/assets/videos/tooltips/animated-beacon.mp4'),
  [TEXT_LABEL_ID]: assetUrl('/assets/videos/tooltips/text-label.mp4'),
  [CLASSIC_ID]: assetUrl('/assets/videos/tooltips/classic.mp4'),
};

export default class TooltipGroupTemplatePreview extends Component<Args> {
  get videoUrl() {
    return VIDEOS[this.args.templateId];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::TemplatePreview': typeof TooltipGroupTemplatePreview;
    'tooltip-group/template-preview': typeof TooltipGroupTemplatePreview;
  }
}
