/* import __COLOCATED_TEMPLATE__ from './new-tour-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { map, union } from '@ember/object/computed';

const BLANK_TEMPLATE = {
  value: null,
  icon: 'new',
  text: 'Blank',
  description: 'Build a new tour from scratch',
};

export default Component.extend({
  router: service(),
  intercomEventService: service(),
  defaultTemplateDropdownItems: [BLANK_TEMPLATE],
  templateDropdownItems: map('templates', function (template) {
    return {
      value: template.get('id'),
      icon: template.get('interfaceIcon'),
      text: template.get('title'),
      description: template.get('description'),
    };
  }),
  allTemplateDropdownItems: union('defaultTemplateDropdownItems', 'templateDropdownItems'),
  actions: {
    transitionToNewProductTour(templateId) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: 'tour',
        template_id: templateId || 'from-scratch',
      });
      this.router.transitionTo('apps.app.tours.new', {
        queryParams: { templateId },
      });
    },
  },
});
