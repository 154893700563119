/* import __COLOCATED_TEMPLATE__ from './tour-card-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  attributeService: service(),
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  composer: null,

  init() {
    this._super(...arguments);
    this.fetchMessengerSettings.perform();
  },

  fetchMessengerSettings: task(function* () {
    let messengerSettings = yield this.store.findRecord(
      'messenger-settings/all',
      this.get('app.id'),
    );
    this.set('messengerSettings', messengerSettings);
  }).drop(),

  createTextComponent(text, style = 'paragraph') {
    return {
      type: 'text',
      text,
      style,
    };
  },

  confirmPreviewCanvas: computed('tour.title', 'tour.description', function () {
    let content = {
      components: [
        this.createTextComponent('Product tour'),
        this.createTextComponent(this.get('tour.title') || 'Untitled tour', 'header'),
        {
          type: 'divider',
        },
        {
          type: 'spacer',
          size: 's',
        },
        this.createTextComponent(this.get('tour.description') || ''),
        {
          id: this.get('tour.id'),
          type: 'button',
          label: 'View Tour',
          style: 'primary',
          action: {
            type: 'submit',
          },
        },
      ],
    };
    return { content };
  }),
});
