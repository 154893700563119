/* import __COLOCATED_TEMPLATE__ from './reporting-chart-datum.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import {
  dateRangeBucketFormatter,
  isBucketGreaterThanDay,
} from 'embercom/lib/inbox/date-range-bucket-formatter';

const CURRENCIES = {
  dollars: 'USD',
};

export default Component.extend({
  intl: service(),
  attributeBindings: ['data-test-reporting-chart-tooltip'],
  'data-test-reporting-chart-tooltip': true,
  iconLabel: null,
  datePattern: computed('data.{currentDate,comparisonDate}', function () {
    let currentDate = this.data.currentDate;
    let comparisonDate = this.data.comparisonDate;

    let currentDateMinusOneDay = moment(currentDate).subtract(1, 'days');
    let currentDatePlusOneDay = moment(currentDate).add(1, 'days');

    let isOneDayRange =
      moment(comparisonDate).isSame(currentDateMinusOneDay, 'days') ||
      moment(comparisonDate).isSame(currentDatePlusOneDay, 'days');
    return comparisonDate && isOneDayRange ? 'dayWithMonthAndTime' : 'dayWithMonth';
  }),
  formattedCurrentDate: computed('intl.locale', 'data.{currentDate,timezone}', function () {
    return this.intl.formatDate(this.data.currentDate, {
      format: this.datePattern,
      timeZone: this.data.timezone,
    });
  }),

  formattedCurrentRangeDate: computed(
    'intl.locale',
    'data.{timezone,currentDate,aggregationInterval,currentStart,currentEnd}',
    function () {
      return dateRangeBucketFormatter(
        this.intl,
        this.data.timezone,
        this.data.currentDate,
        this.data.aggregationInterval,
        this.data.rangeStart,
        this.data.rangeEnd,
      );
    },
  ),
  isBucketGreaterThanDay: computed('data.aggregationInterval', function () {
    return isBucketGreaterThanDay(this.data.aggregationInterval);
  }),
  isCurrency: computed('data.valueUnit', function () {
    return this.data.valueUnit in CURRENCIES;
  }),
  currencyCode: computed('data.valueUnit', 'isCurrency', function () {
    if (this.isCurrency) {
      return CURRENCIES[this.data.valueUnit];
    }
  }),
});
