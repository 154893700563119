/* import __COLOCATED_TEMPLATE__ from './talon-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class TalonComponent extends Component {
  @action
  initEHawk() {
    Talon.eHawkTalon();
  }
}
