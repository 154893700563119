/* import __COLOCATED_TEMPLATE__ from './team-availability.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TeamAvailability extends Component {
  @service attributeService;
  @service appService;
  @service intercomEventService;
  @service intl;
  @service router;

  workflowsBannerContent = this.intl
    .t(
      'operator.task-bots.users.when-users-start-a-conversation.share-typical-reply-time.banner-text',
    )
    .split('%');

  workflowsContentWrapper(text, order) {
    return text[order];
  }
}
