/* import __COLOCATED_TEMPLATE__ from './tags-list.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default Component.extend({
  intercomEventService: service(),
  tagName: '',
  triggerTaggingModalAction: action(function () {
    this.taggingModalAction?.(this.modalController, this.taggable);
  }),
  actions: {
    onRemove(tag) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'removed_tag',
        object: 'tags_list',
        tag: tag.name,
      });
    },
  },
});
