/* import __COLOCATED_TEMPLATE__ from './timeline-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import Range from 'embercom/models/reporting/range';
import { task } from 'ember-concurrency';

const TOUR_STEP_VIEWS_SIGNAL_CONFIG = {
  name: 'default_query',
  debug_name: 'tour_step_views',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'stat_tour_step_view.id',
};

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  appBaseColor: readOnly('app.base_color'),
  reportingService: service(),

  init() {
    this._super(...arguments);
    this.fetchReportingSignal.perform();
  },

  fetchReportingSignal: task(function* () {
    if (this.hideStats || this.get('tour.steps.length') === 1) {
      return;
    }

    let rangeStart = new Date(this.tour.stepsUpdatedAt);
    let range = Range.createFromParams(rangeStart, null, null);
    let aggregations = [{ grouping: 'tour_step.id', interval: 1 }];
    let filters = { 'tour.id': this.tour.id };

    let data = yield this.reportingService.fetchNumericSignal(
      TOUR_STEP_VIEWS_SIGNAL_CONFIG,
      'views',
      range,
      aggregations,
      filters,
    );

    let stepViews = {};
    this.tour.steps.forEach(function (step) {
      stepViews[step.id] = { count: 0 };
    }, this);
    data.context.forEach(function (bucket) {
      stepViews[bucket.key] = { count: bucket.value };
    }, this);

    let firstViewCount = stepViews[this.tour.steps.firstObject.id].count;
    this.tour.steps.forEach(function (step) {
      let viewCount = stepViews[step.id].count;
      stepViews[step.id].percentage = (viewCount * 100) / firstViewCount;
    }, this);

    this.set('stepViews', stepViews);
  }).drop(),
});
