/* import __COLOCATED_TEMPLATE__ from './attribute-and-event-description-content.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TooltipsAttributeAndEventDescription extends Component {
  @service permissionsService;

  get formattedDescription() {
    let desc = this.args.description;
    if (desc) {
      // ensure that description always has full stop (intercom#45181)
      return desc.endsWith('.') ? desc : `${desc}.`;
    } else {
      return '';
    }
  }

  get showEditLink() {
    return (
      this.permissionsService.currentAdminCan('can_access_product_settings') &&
      this.args.isDescriptionModifiable
    );
  }
}
