/* import __COLOCATED_TEMPLATE__ from './tip-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['dismissable-banner'],
  tipKey: null,
  isVisible: true,
  content: '',
  dismissText: 'Dismiss',
  localStorageKey: computed('tipKey', function () {
    return `tip-hidden.${this.tipKey}`;
  }),

  didInsertElement() {
    this._super(...arguments);
    if (this.hasTipBeenHidden()) {
      this.set('isVisible', false);
    }
  },
  hasTipBeenHidden() {
    let hasBeenHidden = false;
    try {
      hasBeenHidden = !!localStorage.getItem(this.localStorageKey);
    } catch (e) {
      // Accessing localStorage threw an error, nothing to do
    }
    return hasBeenHidden;
  },
  actions: {
    dismiss() {
      try {
        localStorage.setItem(this.localStorageKey, 'true');
      } catch (e) {
        // Accessing localStorage threw an error, nothing to do
      }
      this.set('isVisible', false);
    },
  },
});
