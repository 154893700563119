/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type UrlPredicateType = {
  type: string;
  attribute: 'client_attributes.last_visited_url';
  comparison: 'eq' | 'ne' | 'contains' | 'not_contains' | 'starts_with' | 'ends_with' | 'known';
  value: string;
};

export interface Tooltip {
  id: number;
  buildUrl: string;
  cssSelector: string;
  textSelector: string;
  anchorType: number;
  anchorIconType: number;
  anchorColor: string;
  triggerType: number;
  textSelectorType: number;
  blocks: Array<object>;
  urlPredicateGroup: { predicates: UrlPredicateType[] };
  urlPredicatePreview: string;
}

export interface TooltipGroup {
  id: number;
  tooltips: Array<Tooltip>;
  customizationOptions: { backgroundColor: string; fontColor: string };
}

interface Args {
  tooltipGroup: TooltipGroup;
  isEditing: boolean;
}

export default class TooltipGroupEditor extends Component<Args> {
  @service intersectionService: any;
  @service declare contentEditorService: any;

  @tracked currentEditingTooltipId?: number;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.intersectionService.registerEventHandler(
      'getTooltipGroupId',
      () => this.args.tooltipGroup.id,
    );

    this.intersectionService.registerEventHandler('getTooltipToEditId', () =>
      this.consumeTooltipIdToEdit(),
    );

    this.intersectionService.registerEventHandler('tooltips-updated', () => this.tooltipsUpdated());
  }

  get hasTooltips() {
    return this.args.tooltipGroup.tooltips.length > 0;
  }

  get buttonLabel() {
    return this.hasTooltips ? 'Edit tooltips' : 'Add tooltips';
  }

  get buttonIcon() {
    return this.hasTooltips ? 'edit' : 'new';
  }

  @action editTooltip(tooltip: Tooltip, launcher: any) {
    if (this.args.isEditing) {
      this.currentEditingTooltipId = tooltip.id;

      if (tooltip.buildUrl) {
        this.intersectionService.openWindow(tooltip.buildUrl, 'tooltip-editor');
      } else if (
        'openTooltipsSplashScreen' in launcher &&
        typeof launcher.openTooltipsSplashScreen === 'function'
      ) {
        launcher.openTooltipsSplashScreen();
      }
    }
  }

  private consumeTooltipIdToEdit() {
    let result = this.currentEditingTooltipId;
    this.currentEditingTooltipId = undefined;

    return result;
  }

  private async tooltipsUpdated() {
    await this.contentEditorService.ruleset.reload();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipGroup::Editor': typeof TooltipGroupEditor;
    'tooltip-group/editor': typeof TooltipGroupEditor;
  }
}
