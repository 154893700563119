/* import __COLOCATED_TEMPLATE__ from './upfront-collection.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class UpfrontCollection extends Component {
  @service attributeService;
  @service appService;
  @service intercomEventService;
  @service intl;
  @service router;

  workflowsBannerContent = this.intl
    .t('operator.task-bots.workflows-discoverability.context-upfront-info-banner')
    .split('%');

  constructor() {
    super(...arguments);
    this.setupDefaultAttributes();
  }

  get app() {
    return this.appService.app;
  }

  get upfrontCollection() {
    return this.args.upfrontCollection;
  }

  get lastAttributeUnset() {
    if (this.upfrontCollection.attributes.length === 0) {
      return false;
    }

    return this.upfrontCollection.attributes.lastObject === undefined;
  }

  get allowedRuleConditionalAttributes() {
    return this.attributeService.customBotFollowupActionAttributesGroupList;
  }

  get allowedRuleActions() {
    let actions = ['assign-conversation', 'tag-user', 'add-tag-to-conversation'];

    if (this.app.hasSalesforceIntegration) {
      actions.push('send-to-salesforce');
    }

    if (this.app.hasHubSpotIntegration) {
      actions.push('send-to-hubspot');
    }

    if (this.app.hasMarketoEnrichmentIntegration) {
      actions.push('send-to-marketo');
      actions.push('trigger-marketo-campaign');
    }

    if (this.app.canUsePriorityRules) {
      actions.push('change-conversation-priority');
    }

    return actions;
  }

  get hasCollectionAttributes() {
    return (
      this.upfrontCollection.attributes.length > 0 &&
      this.upfrontCollection.attributes.firstObject !== undefined
    );
  }

  @action
  setupDefaultAttributes() {
    if (this.upfrontCollection.attributes.length === 0) {
      if (this.attributeService.conversationCustomAttributes.length === 0) {
        this.upfrontCollection.attributes = [undefined];
      } else {
        this.upfrontCollection.attributes = [
          this.attributeService.conversationCustomAttributes.firstObject.identifier,
        ];
      }
    }
  }

  @action
  addAttributeField() {
    if (this.lastAttributeUnset) {
      return;
    }

    let newAttributes = this.upfrontCollection.attributes.slice();
    newAttributes.push(undefined);
    this.upfrontCollection.attributes = newAttributes;
    this.recordAnalyticsEvent('attribute_field_added');
  }

  @action
  removeAttribute(index) {
    let newAttributes = this.upfrontCollection.attributes.slice();
    newAttributes.splice(index, 1);
    this.upfrontCollection.attributes = newAttributes;
    this.recordAnalyticsEvent('attribute_field_removed');
  }

  @action
  onAttributeItemSelection(index, value) {
    let newAttributes = this.upfrontCollection.attributes.slice();
    newAttributes[index] = value;
    this.upfrontCollection.attributes = newAttributes;
  }

  recordAnalyticsEvent(action, context) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'upfront-collection-users',
      place: 'task_bot_settings',
      context,
    });
  }

  workflowsContentWrapper(text, order) {
    return text[order];
  }
}
