/* import __COLOCATED_TEMPLATE__ from './content-panel.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import EmberObject, { computed, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';

export default Component.extend({
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  // We make a fake variation as a bridge between the tour and the format
  // expected by the sender-dropdown component
  variation: computed('tour.{fromId,senderType}', function () {
    return EmberObject.create({
      dynamicSender: this.tour.senderType === 'owner',
      from: this.tour.from,
      showDynamicSender: true,
    });
  }),
  colorOptions: DEFAULT_MESSENGER_COLORS,
  hasCustomButtonColor: computed('tour.buttonColor', function () {
    return !isEmpty(this.tour.buttonColor);
  }),
  buttonColor: computed('tour.buttonColor', function () {
    return isEmpty(this.tour.buttonColor) ? this.app.base_color : this.tour.buttonColor;
  }),
  shouldShowSender: computed('tour.senderType', function () {
    if (this.tour.senderType === 'no_sender') {
      return false;
    }
    return true;
  }),
  setButtonColor: action(function (color) {
    this.set('tour.buttonColor', color);
  }),
  actions: {
    setFrom(selected) {
      let { isDynamicSender } = selected.model;

      this.set('tour.senderType', isDynamicSender ? 'owner' : 'standard');

      if (!isDynamicSender) {
        this.send('setAuthor', selected);
      }
    },
    setAuthor(selected) {
      let { id } = selected.model;
      this.set('tour.fromId', id);
    },
    openBuildUrl(url, launcher) {
      launcher.openTourBuilder(url);
      return false;
    },
    toggleHasCustomButtonColor() {
      this.toggleProperty('hasCustomButtonColor');

      if (this.hasCustomButtonColor) {
        this.setButtonColor(this.app.base_color);
      } else {
        this.setButtonColor(null);
      }
    },

    setHiddenSenderOption(showSender) {
      if (showSender === false) {
        this.set('tour.senderType', 'no_sender');
      } else {
        this.set('tour.fromId', this.app.currentAdmin.id);
        this.set('tour.senderType', 'standard');
      }
    },
  },
});
